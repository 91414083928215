import React from "react"
import { PriceContext } from "../lib/types"

// Not used for cart, which has a different logic

export const Price = ({ product, context = "normal", className }: { product: any, context?: PriceContext, className?: string}) => {
    const { price, pricebraderie, pricepro } = product
    return (
        (context === "braderie") ? <div className={className}>
            <span style={{textDecoration: "line-through", marginRight: 30}}>
                { price}€
            </span>
            <span style={{color: "red"}}>
                { pricebraderie }€
            </span>
        </div> : (context === "pro") ?
        <div className="flex flex-wrap">
            <span className="italic mr-2 whitespace-nowrap md:mr-6">
            Prix public : {price}€
            </span>
            <span>
            Wholesale : { pricepro }€ HT
            </span></div> :
        <div className={className}>
            { price }€
        </div>
)}

// DOES NOT SUPPORT PRO
export const cartPrice = ( product, context ) => {
    const { price, pricebraderie, pricepro } = product
    return (context === "braderie") ? pricebraderie : price
}