import Image from "next/image"
import { cx, imgsrc } from "../lib/common"
import styles from "./SuperImage.module.css"

// Fallback for browsers that don't support aspect-ratio
// - Provide `fallbackWidth`: an estimation of the width of the image (unit: % of viewport width)
// (this is closely related to `sizes`)
// - So that we set a fixed height on the image
// - And object-fit: cover to avoid distortion
// - FALLBACK DOES NOT SUPPORT HORIZONTAL CROP ! (THAT'S OK)

export const SuperImage = ({ pic, optim, priority, className, alt = "", horizontalCropRatio }) => {
  if (!pic) {
    return <div>No pic</div>
  }
  if (!optim) {
    console.warn("optim not provided to SuperImage", pic.src)
  }
  const { src, dim } = pic
  const { sizes, fallbackWidth = {} } = optim || {}
  const [w, h] = dim
  const alpha = typeof horizontalCropRatio === "number"
    ? horizontalCropRatio / 100 
    : 0
  const width = `${Math.floor((1 / (1 - alpha)) * 100)}%`
  const left = `-${Math.floor((alpha / (1 - alpha)) * 50)}%`

  return (
    <div className={cx(styles.wrapper, className)}>
      <div
        className={cx(styles.imageWrapper, horizontalCropRatio && styles.cropped)}
        style={{
          '--width': width,
          '--left': left,
          '--aspect-ratio': `${w} / ${h}`,
          '--aspect-ratio-number': w / h,
          '--fallback-width-desktop': fallbackWidth.d || '1200px',
          '--fallback-width-mobile': fallbackWidth.m || '100vw',
        }}
        data-optim={JSON.stringify(optim)}
        data-horizontal-crop-ratio={JSON.stringify(horizontalCropRatio)}
        >
        <Image src={imgsrc(src)} fill sizes={sizes} priority={priority} alt={alt} 
          style={{objectFit: 'cover'}} /> 
          {/* object-fit used when aspect-ratio is not supported -- should have no effect in normal mode */}
      </div>
    </div>
  )
}
