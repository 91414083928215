import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { useState } from 'react'
import Modal from 'react-responsive-modal'
import styles from '../styles/Footer.module.css'
import { email } from './basics'
import { CGV, Livraison, Mentions } from './Legal'

export const Avantages = () => {
    const t = useTranslations("layout")
    return (
        <div className={styles.avantages}>
            {[1, 2, 3].map(idx => (
                <div key={idx}>
                    <div className={styles.header}>{t(`avantages${idx}a`)}</div>
                    <div>{t(`avantages${idx}b`)}</div>
                </div>
            ))}
        </div>
    )
}

export const EmailLink = () => (
    <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
        {email}
    </a>
)

export const Phone = () => {
    const t = useTranslations('layout')
    return <div>{t("tel")}</div>
}

export const InstaLink = () => (
    <a href="https://www.instagram.com/mariannebymariejordane/" target="_blank" rel="noopener noreferrer">
        @mariannebymariejordane
    </a>
)

const FbLink = () => (
    <a href="https://www.facebook.com/mariannebymariejordane" target="_blank" rel="noopener noreferrer">
        @mariannebymariejordane
    </a>
)

export const Footer = () => {
    const [modal, setModal] = useState(null)
    const t = useTranslations("layout")
    const show = what => {
        setModal(what); return false;
    }
    return (<div className={styles.footer}>
        <div>
            <div className={styles.header}>{t("contacts")}</div>
            <div>
                <EmailLink />
            </div>
            <div>
                <Phone />
            </div>
            <div>
                Insta : <InstaLink />
            </div>
            <div>
                FB : <FbLink />
            </div>
        </div>
        <div>
            <div className={styles.header}>{t("lb")}</div>
            <div className="adr boutique">
                <a href="https://goo.gl/maps/JvcXqaYs93RFHKzf7" target="_blank" rel="noopener noreferrer">
                    {t("lb1")}
                    <br />
                    {t("lb2")}
                </a>
                <div className="hrs boutique">
                    {t("lb3")}<br />
                    {t("lb4")}
                    <br /> {t("lb5")}
                </div>
            </div>
        </div>
        <div>
            <div className={styles.header}>{t("la")}</div>
            <div className="adr atelier">
                <a href="https://goo.gl/maps/1xzuKsFt9RLdX1tTA" target="_blank" rel="noopener noreferrer">
                    {t("la1")}
                    <br />
                    {t("la2")}
                </a>
            </div>
            <div className="hrs atelier">
                {t("la3")} <br />{t("la4")}
            </div>
        </div>
        <div>
            <div className={styles.header}>{t("ap")}</div>
            <div>
                <Link href="/retour">{t('return')}</Link>
            </div>
            <div>
                <Link href="/faq">{t('faq')}</Link>
            </div>
            <div>
                <a href="#" onClick={() => show('mentions')}>{t("ap1")}</a>
            </div>
            <div>
                <Link href="/expeditions-et-politique-de-retour">{t("ap2")}</Link>
            </div>
            <div>
                <Link href="/conditions-generales-de-vente">{t("ap3")}</Link>
            </div>
        </div>
        <Modal open={modal === 'mentions'} onClose={() => setModal(false)}>
            <Mentions />
        </Modal>
        {/* <Modal open={modal === 'livraison'} onClose={() => setModal(false)}>
            <Livraison />
        </Modal>
        <Modal open={modal === 'cgv'} onClose={() => setModal(false)}>
            <CGV />
        </Modal> */}
    </div>)
}

export const UnDouteUneQuestion = () => {
    const t = useTranslations('layout')
    return (
    <div className={styles.undoute}>
        <h2>{t("doute")}</h2>
        <Phone />
        <EmailLink />
    </div>
)}